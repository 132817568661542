import serverCall from "../serverCall";

const getPromocode = async (pageNumber, pageSize) => {
  const response = await serverCall.get(
    `/promocode/admin/getAll?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return response;
};

const createPromocode = async (promocode, ptomocodeType) => {
  const response = await serverCall.post(
    `/promocode/admin/create?promocode=${promocode}&ptomocodeType=${ptomocodeType}`
  );
  return response;
};

const deletePromocode = async (id) => {
  const response = await serverCall.delete(`/promocode/admin/delete/${id}`);
  return response;
};

const enterPromoCode = async (promoCode) => {
  const response = await serverCall.post(
    `/user/usePromoCode?promoCode=${promoCode}`
  );
  return response;
};

const PromoCodeServices = {
  getPromocode,
  createPromocode,
  deletePromocode,
  enterPromoCode,
};

export default PromoCodeServices;
