import React, { useEffect, useState } from "react";
import Plus from "../../../../../assets/images/icon/plus.svg";
import Edit from "../../../../../assets/images/icon/edit.svg";
import Delete from "../../../../../assets/images/icon/delete.svg";
import useDialog from "../../../../../hooks/useDialog";
import AddDeck from "./AddDeck";
import DeleteAll from "../myCourses/DeleteAll";
import DeckServices from "../../../../../services/deck.service";
import Search from "../../../../../assets/images/icon/Search.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Pagination from "../../Pagination";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const Deck = () => {
  const {
    open,
    handleOpen,
    handleClose,
    setActiveTab,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
  } = useDialog();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [data, setData] = useState([]);
  const [cards, setCards] = useState([0]);
  const [deleteID, setDeleteID] = useState("");
  const [involveDrink, setInvolveDrink] = useState("Yes");
  const [dropdownTitle, setDropdownTitle] = useState(false);
  const [selectedData, setSelectedData] = useState("PLAYER");
  const [minActive, setMinActive] = useState(2);
  const [playersActive, setPlayersActive] = useState(0);
  const [prevFormikState, setPrevFormikState] = useState(null);
  const { editOpen, handleEditOpen, handleEditClose } = useDialog();
  const [clickedCardIndex, setClickedCardIndex] = useState(-1);
  const [addNewDeck, setAddNewDeck] = useState(false);
  const [update, setUpdate] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getAllDecks = async () => {
    try {
      const response = await DeckServices.getAllDecks(
        pageNumber,
        pageSize,
        searchText
      );
      setData(response.data.apiresponse.data.data);
      setTotalRows(response.data.apiresponse.data.count);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    getAllDecks();
  }, [pageNumber, pageSize, searchText]);

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const initialValue = {
    cards: [
      {
        involvesDrinking: true,
        minPlayer: 0,
        targetPlayer: 0,
        text: "",
        title: "",
        type: "",
      },
    ],
    name: "",
  };

  const validationSchema = yup.object().shape({
    cards: yup.array().of(
      yup.object().shape({
        involvesDrinking: yup
          .boolean()
          .required("Please specify if it involves drinking"),
        minPlayer: yup
          .number()
          .required("Please specify the minimum number of players"),
        targetPlayer: yup.number().required("Please specify the target player"),
        text: yup.string().required("Please provide text"),
        title: yup.string().required("Please provide a title"),
        type: yup.string().required("Please provide a type"),
      })
    ),
    name: yup.string().required("Please provide a name"),
  });

  const handleFormSubmit = async (values) => {
    try {
      if (!values.id) {
        await DeckServices.createDeck(values);
      } else {
        await DeckServices.updateDeck(values.id, values);
      }
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
    getAllDecks();
    handleClose();
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleDialogOpen = async (id) => {
    handleOpen();
    if (id) {
      setUpdate(true);
      try {
        const response = await DeckServices.getDeckDetails(id);
        const result = response.data.apiresponse.data;
        formik.setFieldValue("id", result.id);
        formik.setFieldValue("name", result.name);
        formik.setFieldValue("cards", result.cards);

        setSelectedData(result?.cards?.map((item) => item.type));

        setCards([result.cards.length]);
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    } else {
      setUpdate(false);
      formik.resetForm();
    }
  };

  const closeFirstDialog = () => {
    handleClose();
    setCards([]);
    formik.resetForm();
  };

  const deleteCourse = async (id) => {
    handleDeleteOpen();
    setDeleteID(id);
  };

  const finalDeleteButton = async (id) => {
    try {
      await DeckServices.deleteDeck(id);
      getAllDecks();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
    handleDeleteClose();
  };

  const handleNextPage = () => {
    if (pageNumber * pageSize < totalRows) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    setPrevFormikState(formik.values.cards);
  }, [formik.values]);

  const handleDrinkingChange = (index) => {
    setInvolveDrink(involveDrink === "Yes" ? "No" : "Yes");
    formik.setFieldValue(
      `cards[${index}].involvesDrinking`,
      involveDrink === "Yes" ? false : true
    );
  };

  const changeMinPlayer = (number, index) => {
    setMinActive(number);
    formik.setFieldValue(`cards[${index}].minPlayer`, number);
  };
  const changePlayersToTarget = (number, index) => {
    setPlayersActive(number);
    formik.setFieldValue(`cards[${index}].targetPlayer`, number);
  };

  const cancel = () => {
    if (addNewDeck) {
      formik.setFieldValue("cards", prevFormikState?.slice(0, -1));
      setAddNewDeck(false);
    }
    setClickedCardIndex(-1);
    handleEditClose();
    setPlayersActive(0);
    setMinActive(2);
    handleOpen();
  };

  const submit = () => {
    handleEditClose();
    handleOpen();
    setPlayersActive(0);
    setMinActive(2);
    setClickedCardIndex(-1);
  };

  const titleDropdown = [
    { label: "PLAYER", value: "PLAYER" },
    { label: "PUNISHMENT", value: "PUNISHMENT" },
    { label: "MINIGAME", value: "MINIGAME" },
  ];

  const handleEditDeckOpen = (index) => {
    handleEditOpen();
    setClickedCardIndex(index);
  };

  return (
    <>
      <div className="">
        <div className="row m-0 py-4">
          <div className="col-12 col-lg-4">
            <h1 className="fs-3 m-0 fw-semibold lh-lg">My Desk</h1>
          </div>
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <div className="dashboard-inputs">
              <img src={Search} alt="Search" />
              <input
                type="text"
                className="py-2 px-3"
                placeholder="Enter Deck Name....."
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex  justify-content-end">
            <button
              className="add-course btns"
              type="button"
              onClick={() => handleDialogOpen("")}
            >
              <span>
                <img src={Plus} alt="Pluse" />
              </span>
              Add Desk
            </button>
          </div>
        </div>
        {data !== "" && data != "" ? (
          <div className="my-course-data rounded-4 overflow-hidden bg-white">
            <div className="overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>Deck Name</th>
                    <th>Total Cards</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => {
                    return (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.cardCount}</td>
                        <td>
                          <div className="d-flex gap-3 justify-content-center">
                            <div
                              className="action-btn"
                              onClick={() => handleDialogOpen(item.id)}
                            >
                              <img src={Edit} alt="Edit" />
                            </div>
                            <div
                              className="action-btn"
                              onClick={() => deleteCourse(item.id)}
                            >
                              <img src={Delete} alt="Edit" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              data={data}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              totalRows={totalRows}
            />
            {/* <div>Pagination Add baki chhe</div> */}
          </div>
        ) : (
          <div className="my-course-data rounded-4 overflow-hidden bg-white px-3 py-3">
            No Data Available
          </div>
        )}
      </div>
      <AddDeck
        show={open}
        handleOpen={handleOpen}
        onHide={handleClose}
        formik={formik}
        cards={cards}
        closeFirstDialog={closeFirstDialog}
        handleEditDeckOpen={handleEditDeckOpen}
        handleEditOpen={handleEditOpen}
        editOpen={editOpen}
        handleEditClose={handleEditClose}
        clickedCardIndex={clickedCardIndex}
        setClickedCardIndex={setClickedCardIndex}
        cancel={cancel}
        selectedData={selectedData}
        setDropdownTitle={setDropdownTitle}
        dropdownTitle={dropdownTitle}
        titleDropdown={titleDropdown}
        setSelectedData={setSelectedData}
        handleDrinkingChange={handleDrinkingChange}
        involveDrink={involveDrink}
        changeMinPlayer={changeMinPlayer}
        minActive={minActive}
        playersActive={playersActive}
        submit={submit}
        changePlayersToTarget={changePlayersToTarget}
        setAddNewDeck={setAddNewDeck}
        update={update}
      />
      <DeleteAll
        show={deleteOpen}
        onHide={handleDeleteClose}
        path={"/activeTab === 1"}
        finalDeleteButton={finalDeleteButton}
        deleteID={deleteID}
        tab={"Deck"}
      />
    </>
  );
};

export default Deck;
