import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Less from "../../../../../assets/images/icon/less.svg";
import Arrows from "../../../../../assets/images/icon/arrow-bold.svg";
import Plus from "../../../../../assets/images/icon/plus.svg";

const EditCoursePars = (props) => {
  const {
    handleEditClose,
    editOpen,
    formik,
    holes,
    addHole,
    removeHole,
    clearHoles,
    decreaseHoles,
    increaseHoles,
    submit,
  } = props;

  return (
    <>
      <div className="edit-courses">
        <Modal
          {...props}
          centered
          show={editOpen}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            className="py-2 card-title"
            closeButton
            onClick={clearHoles}
          >
            <Modal.Title>Edit COURSE PARS</Modal.Title>
          </Modal.Header>
          <form>
            <div className="p-3 position-sticky bg-white">
              <div className=" mb-3">
                <div className="titels">
                  <span className="text-white">COURSE PARS</span>
                </div>
              </div>
              <div className="dailog-card border-color">
                <div className="row m-0 p-0 px-sm-5 px-4 mb-3">
                  <div className="col-4 col-sm-6 p-0 text-white fw-semibold">
                    Holes
                  </div>
                  <div className="col-8 col-sm-6 p-0 text-white fw-semibold text-center">
                    Pars
                  </div>
                </div>

                {/* Hole Count */}
                {/* {[...Array(holes)].map((_, index) => (
                  <div className="position-sticky mb-3">
                    <div className="input-field row m-0 align-items-center">
                      <span className="text-white ps-sm-4 p-0 col-4 col-sm-6">
                        Hole {index + 1}
                      </span>
                      <div className="hole-counting col-8 col-sm-6 p-0 row m-0">
                        <div className="hole-plus col-8 p-0 ">
                          <img
                            src={Arrows}
                            alt="Arrow"
                            onClick={() =>
                              decreaseHoles(
                                formik.values.holes[index]?.par,
                                index
                              )
                            }
                          />
                          <span>{formik.values.holes[index]?.par}</span>
                          <img
                            src={Arrows}
                            alt="Arrow"
                            onClick={() =>
                              increaseHoles(
                                formik.values.holes[index]?.par,
                                index
                              )
                            }
                          />
                        </div>
                        <div
                          className="col-4 p-0 d-flex justify-content-end"
                          onClick={() => removeHole(index)}
                        >
                          <div
                            className="hole-remove bg-white rounded-3 bg-opacity-25 p-1"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={Less} alt="Less" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
                {holes.map((hole, index) => (
                  <div className="position-sticky mb-3" key={index}>
                    <div className="input-field row m-0 align-items-center">
                      <span className="text-white ps-sm-4 p-0 col-4 col-sm-6">
                        Hole {hole.index}
                      </span>
                      <div className="hole-counting col-8 col-sm-6 p-0 row m-0">
                        <div className="hole-plus col-8 p-0 ">
                          <img
                            src={Arrows}
                            alt="Arrow"
                            onClick={() => decreaseHoles(index)}
                          />
                          <span>{hole.par}</span>
                          <img
                            src={Arrows}
                            alt="Arrow"
                            onClick={() => increaseHoles(index)}
                          />
                        </div>
                        <div
                          className="col-4 p-0 d-flex justify-content-end"
                          onClick={() => removeHole(index)}
                        >
                          <div
                            className="hole-remove bg-white rounded-3 bg-opacity-25 p-1"
                            style={{ cursor: "pointer" }}
                          >
                            <img src={Less} alt="Less" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Add Button */}
                <div className="position-sticky justify-content-center d-flex">
                  <button
                    className="add-course hole btns"
                    type="button"
                    onClick={addHole}
                  >
                    <span>
                      <img src={Plus} alt="Pluse" />
                    </span>
                    Add Course
                  </button>
                </div>
              </div>
            </div>
            <Modal.Footer className="border-0 gap-3 px-3 pb-4">
              <button
                className="btns-d btns cancel"
                type="button"
                onClick={clearHoles}
              >
                Cancel
              </button>
              <button
                className="btns-d btns add"
                type="button"
                onClick={submit}
              >
                Save
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default EditCoursePars;
