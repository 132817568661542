import React from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { ANCHORORIGIN, ERROR } from "../../utils/constants";
import { login, setLoggedIn } from "../../store/slice/auth";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required!"),
    password: yup.string().required("Password is required"),
  });

  const handleFormSubmit = async (values) => {
    const { email, password } = values;

    try {
      await dispatch(login({ email, password })).unwrap();
      dispatch(setLoggedIn(true));
    } catch (e) {
      formik.resetForm();
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } finally {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="login-forms">
        <div className="container">
          <div className="login-main position-sticky">
            <div className="title">Login</div>
            <form onSubmit={formik.handleSubmit}>
              <div className="user__details my-4">
                <div className="input__box w-100">
                  <span className="details">Email</span>
                  <div className="input-field">
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="input__box w-100">
                  <span className="details">Password</span>
                  <div className="input-field">
                    <input
                      type="password"
                      name="password"
                      placeholder="Enter Your Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      required
                    />
                  </div>
                  <div className="forgot-password mt-2 text-end">
                    <div
                      onClick={() => navigate("/forgot-password")}
                      className="text-white fw-normal cursor-pointer"
                    >
                      Forgot Password?
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="login p-3 h-100 w-100 fw-semibold fs-6"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div className="forgot-password mt-4 text-center text-white">
                Create Account?{" "}
                <span onClick={() => navigate("/register")}>Register</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
