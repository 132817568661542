import serverCall from "../serverCall";

const getGameHistory = async (pageNumber, pageSize, searchText) => {
  if (searchText) {
    const response = await serverCall.post(
      `/game/web/getUsersGame?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`
    );
    return response;
  } else {
    const response = await serverCall.post(
      `/game/web/getUsersGame?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return response;
  }
};

const getGame = async (id) => {
  const response = await serverCall.get(`/game/${id}`);
  return response;
};

const GameHistoryServices = {
  getGameHistory,
  getGame,
};

export default GameHistoryServices;
