import { useFormik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import PromoCodeServices from "../../../../../services/promocode.service";
import { enqueueSnackbar } from "notistack";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";

const HavePromoCode = (props) => {
  const { show, onHide, handleOpen } = props;

  const formik = useFormik({
    initialValues: {
      promocode: "",
    },
    validationSchema: yup.object().shape({
      promocode: yup.string().required("Please Enter Promocode!"),
    }),
    onSubmit: async (values) => {
      try {
        await PromoCodeServices.enterPromoCode(values.promocode);
        onHide();
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
      formik.resetForm();
    },
  });

  return (
    <div className="add-courses">
      <Modal
        centered
        {...props}
        show={show}
        backdrop="static"
        keyboard={false}
        className="rounded-5"
      >
        <Modal.Header closeButton onClick={onHide} className="card-title py-2">
          <Modal.Title>Add Promo Code</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-3 bg-white subscription-modals">
            <div className="dailog-card py-4">
              <div className=" d-flex flex-column gap-2 position-sticky">
                <div className="input-field ">
                  <input
                    type="text"
                    placeholder="Promo Code"
                    name={`promocode`}
                    value={formik.values.promocode}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched.promocode && formik.errors.promocode && (
                  <div className="error-text">{formik.errors.promocode}</div>
                )}
                <div
                  onClick={handleOpen}
                  className="text-white ms-auto cursor-pointer text-decoration-underline"
                >
                  Don’t have promo code?
                </div>
              </div>
            </div>
          </div>
          <div className="border-0 gap-3 px-3 m-0 pt-1 pb-2">
            <div className="">
              <button
                className="btns border-0 w-100 add fw-semibold edit position-relative"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default HavePromoCode;
