import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setToken } from "./store/slice/auth";
import Routes from "./routes/routes";
import "./App.css";

function App() {
  const dispatch = useDispatch();

  const mulliganStorageString = localStorage.getItem("mulligan-storage");
  const mulliganStorage = JSON.parse(mulliganStorageString);

  const tokenjson = mulliganStorageString;
  useEffect(() => {
    if (tokenjson?.token) {
      dispatch(setToken(tokenjson?.token));
    }
    if (localStorage.getItem("isLoggedIn")) {
      dispatch(setLoggedIn(JSON.parse(localStorage.getItem("isLoggedIn"))));
    }
  }, []);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin = isLoggedIn || (mulliganStorage && mulliganStorage.token);

  return (
    <div className="App">
      <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE}>
        <React.Suspense>
          <Routes isLoggedIn={isLoggedin} />
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}
export default App;
