import axios from "axios";
import config from "./config";

const BASE_URL = config.baseApiURL;

const customAxios = axios.create({
  baseURL: BASE_URL,
});

const requestHandler = (request) => {
  const mulliganStorageString = localStorage.getItem("mulligan-storage");
  const mulliganStorage = JSON.parse(mulliganStorageString);
  if (mulliganStorageString) {
    const token = mulliganStorage.token;
    request.headers.Authorization = `${token}`;
  }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location = "/home";
  }

  return response;
};

const requestErrorHandler = (error) => {
  return Promise.reject(error);
};

const responseErrorHandler = async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = "/home";
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => requestErrorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  responseErrorHandler
);

export default customAxios;
