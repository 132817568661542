import React, { useEffect, useState } from "react";
import Plus from "../../../../../assets/images/icon/plus.svg";
import Delete from "../../../../../assets/images/icon/delete.svg";
import Pagination from "../../Pagination";
import PromoCodeServices from "../../../../../services/promocode.service";
import useDialog from "../../../../../hooks/useDialog";
import AddPromoCode from "./AddPromoCode";
import { useFormik } from "formik";
import DeleteAll from "../myCourses/DeleteAll";
import * as yup from "yup";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const PromoCode = () => {
  const {
    open,
    handleOpen,
    handleClose,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
  } = useDialog();
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dropdownTitle, setDropdownTitle] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [deleteID, setDeleteID] = useState("");

  const handleNextPage = () => {
    if (pageNumber * pageSize < data.count) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const getPromocode = async () => {
    try {
      const response = await PromoCodeServices.getPromocode(
        pageNumber,
        pageSize
      );
      setData(response.data.apiresponse.data);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPromocode();
  }, []);

  const titleDropdown = [
    { label: "GAME", value: "GAME" },
    { label: "MONTHLY", value: "MONTHLY" },
    { label: "YEARLY", value: "YEARLY" },
  ];
  const formik = useFormik({
    initialValues: {
      promocodeType: "",
      promocode: "",
    },
    validationSchema: yup.object().shape({
      promocodeType: yup.mixed().required("Please Enter Promocode Type!"),
      promocode: yup.string().required("Please Enter Promocode!"),
    }),
    onSubmit: async (values) => {
      const { promocode, promocodeType } = values;
      try {
        await PromoCodeServices.createPromocode(promocode, promocodeType);
        getPromocode();
        handleClose();
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
      formik.resetForm();
    },
  });

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    formik.setFieldValue("promocode", randomPassword);
  };

  const deleteCourse = async (id) => {
    handleDeleteOpen();
    setDeleteID(id);
  };

  const finalDeleteButton = async (id) => {
    try {
      await PromoCodeServices.deletePromocode(id);
      getPromocode();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
    handleDeleteClose();
  };

  return (
    <div className="">
      <div className="row m-0 py-4">
        <div className="col-12 col-sm-6">
          <h1 className="fs-3 m-0 fw-semibold lh-lg">My Courses</h1>
        </div>
        <div className="col-12 col-sm-6 d-flex  justify-content-end">
          <button className="add-course btns" onClick={handleOpen}>
            <span>
              <img src={Plus} alt="Pluse" />
            </span>
            Generate Promo Code
          </button>
        </div>
      </div>

      {data !== "" && data != "" ? (
        <div className="my-course-data rounded-4 overflow-hidden bg-white">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Promo Code</th>
                  <th>Type</th>
                  <th>Is used?</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.code}</td>
                      <td>{item.promocodeType}</td>
                      <td>{item.used ? "Yes" : "No"}</td>
                      <td>
                        <div className="me-2 d-flex justify-content-center">
                          <div
                            className="action-btn"
                            onClick={() => deleteCourse(item.id)}
                          >
                            <img
                              src={Delete}
                              alt="Edit"
                              width={25}
                              height={25}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            data={data}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            totalRows={data.length}
          />
        </div>
      ) : (
        <div className="my-course-data rounded-4 overflow-hidden bg-white px-3 py-3">
          No Data Available
        </div>
      )}
      {open && (
        <AddPromoCode
          show={open}
          onHide={handleClose}
          setDropdownTitle={setDropdownTitle}
          dropdownTitle={dropdownTitle}
          titleDropdown={titleDropdown}
          formik={formik}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          generateRandomPassword={generateRandomPassword}
        />
      )}
      {/* {open && (
        <HavePromoCode
          show={open}
          onHide={handleClose}
          formik={formik2}
          setPromoCodeOpen={setPromoCodeOpen}
        />
      )} */}

      {deleteOpen && (
        <DeleteAll
          show={deleteOpen}
          onHide={handleDeleteClose}
          finalDeleteButton={finalDeleteButton}
          deleteID={deleteID}
          tab={"Promocode"}
        />
      )}
    </div>
  );
};

export default PromoCode;
