import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/footer/page";
import Header from "./components/header/Header";

const MatxLayout = () => {
  return (
    <Fragment>
      <div className="page mb-5">
        <Header />
        {/* <Sidebar /> */}
        <Outlet />
        {/* <Rightside /> */}
      </div>
      {/* <Footer /> */}
    </Fragment>
  );
};
export default MatxLayout;
