import React, { useState } from "react";
import MyCourses from "./dashboardTab/myCourses/MyCourses";
import GameHistory from "./dashboardTab/gameHistory/GameHistory";
import MyAccount from "./dashboardTab/myAccount/MyAccount";
import Deck from "./dashboardTab/deck/Deck";
import Cours from "../../../assets/images/icon/courses.svg";
import Desks from "../../../assets/images/icon/desk.svg";
import Historys from "../../../assets/images/icon/history.svg";
import Accounts from "../../../assets/images/icon/account.svg";
import Promocode from "../../../assets/images/icon/promocode.svg";
import Menus from "../../../assets/images/icon/menu.svg";
import Credit from "../../../assets/images/icon/infinity.svg";

import Offcanvas from "react-bootstrap/Offcanvas";
import PromoCode from "./dashboardTab/promoCode/PromoCode";
import HavePromoCode from "./dashboardTab/promoCode/HavePromocode";
import ManageSubscription from "./dashboardTab/myAccount/ManageSubscription";

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeTab, setActiveTab] = useState(0);
  const mulliganStorageString = localStorage.getItem("mulligan-storage");
  const mulliganStorage = JSON.parse(mulliganStorageString);

  const handleTabClick = (tabIndex) => {
    handleClose();
    setActiveTab(tabIndex);
  };

  const handlePromoCOdeClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="dashboard-tabs">
        {/* Mobile View */}
        <div className="menu-tab d-block d-lg-none" onClick={handleShow}>
          <img src={Menus} alt="Menu" />
        </div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          backdrop="static"
          placement="end"
          style={{ backgroundColor: "#BEDBB6" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="tab-buttons w-100">
              <div
                onClick={() => handleTabClick(0)}
                className={`tab-background sidebar-bg mb-3 rounded-3 ${
                  activeTab === 0
                    ? "active tab-image tab-active"
                    : "slider-tabs"
                }`}
              >
                <div className="tabBtns">
                  <img src={Cours} alt="My Courses" />
                  My Courses
                </div>
              </div>
              <div
                onClick={() => handleTabClick(1)}
                className={`tab-background sidebar-bg mb-3 rounded-3 ${
                  activeTab === 1
                    ? "active tab-image tab-active"
                    : "slider-tabs"
                }`}
              >
                <div className="tabBtns">
                  <img src={Desks} alt="Desk" />
                  Deck
                </div>
              </div>
              <div
                onClick={() => handleTabClick(2)}
                className={`tab-background sidebar-bg mb-3 rounded-3 ${
                  activeTab === 2
                    ? "active tab-image tab-active"
                    : "slider-tabs"
                }`}
              >
                <div className="tabBtns">
                  <img src={Historys} alt="History" />
                  Game History
                </div>
              </div>
              <div
                onClick={() => handleTabClick(3)}
                className={`tab-background sidebar-bg mb-3 rounded-3 ${
                  activeTab === 3
                    ? "active tab-image tab-active"
                    : "slider-tabs"
                }`}
              >
                <div className="tabBtns">
                  <img src={Accounts} alt="Accunt" />
                  My account
                </div>
              </div>
              <div
                onClick={() => handleTabClick(4)}
                className={`tab-background sidebar-bg mb-3 rounded-3 ${
                  activeTab === 4
                    ? "active tab-image tab-active"
                    : "slider-tabs"
                }`}
              >
                <div className="tabBtns">
                  <img src={Promocode} alt="Accunt" />
                  Promo code
                </div>
              </div>
              <div className="position-sticky d-flex justify-content-center mt-5">
                <button className="credits" onClick={() => setIsOpen(true)}>
                  <img src={Credit} /> Credits
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Web View */}
        <div className="tab-view d-lg-block d-none">
          <div className="container">
            <div className="tab-buttons d-flex">
              <div
                onClick={() => handleTabClick(0)}
                className={`tab-background ${
                  activeTab === 0 ? "active tab-image" : ""
                }`}
              >
                <div className="tabBtns">
                  <img src={Cours} alt="My Courses" />
                  My Courses
                </div>
              </div>
              <div
                onClick={() => handleTabClick(1)}
                className={`tab-background ${
                  activeTab === 1 ? "active tab-image" : ""
                }`}
              >
                <div className="tabBtns">
                  <img src={Desks} alt="Desk" />
                  Deck
                </div>
              </div>
              {mulliganStorage?.user !== "ADMIN" && (
                <div
                  onClick={() => handleTabClick(2)}
                  className={`tab-background ${
                    activeTab === 2 ? "active tab-image" : ""
                  }`}
                >
                  <div className="tabBtns">
                    <img src={Historys} alt="History" />
                    Game History
                  </div>
                </div>
              )}
              {mulliganStorage?.user === "ADMIN" && (
                <div
                  onClick={() => handleTabClick(4)}
                  className={`tab-background ${
                    activeTab === 4 ? "active tab-image" : ""
                  }`}
                >
                  <div className="tabBtns">
                    <img src={Promocode} alt="Accunt" />
                    Promo code
                  </div>
                </div>
              )}
              <div
                onClick={() => handleTabClick(3)}
                className={`tab-background ${
                  activeTab === 3 ? "active tab-image" : ""
                }`}
              >
                <div className="tabBtns">
                  <img src={Accounts} alt="Accunt" />
                  My account
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="pt-1">
            <div className="tab-content">
              {activeTab === 0 && (
                <div style={{ alignItems: "center", backgroundColor: "" }}>
                  <MyCourses />
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <Deck setActiveTab={setActiveTab} activeTab={activeTab} />
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <GameHistory />
                </div>
              )}
              {activeTab === 4 && (
                <div>
                  <PromoCode />
                </div>
              )}
              {activeTab === 3 && (
                <div>
                  <MyAccount />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {open && (
        <ManageSubscription
          show={open}
          onHide={() => setOpen(false)}
          handlePromoCOdeClose={handlePromoCOdeClose}
        />
      )}
      {isOpen && (
        <HavePromoCode
          show={isOpen}
          onHide={handlePromoCOdeClose}
          handleOpen={() => setOpen(true)}
        />
      )}
    </>
  );
};

export default Dashboard;
