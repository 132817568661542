import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GameHistoryServices from "../../../../../services/gameHistory.service";
import Search from "../../../../../assets/images/icon/Search.svg";
import moment from "moment";
import Pagination from "../../Pagination";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const GameHistory = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const getGameHistory = async () => {
    try {
      const response = await GameHistoryServices.getGameHistory(
        pageNumber,
        pageSize,
        searchText
      );
      setData(response.data.apiresponse.data);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    getGameHistory();
  }, [pageNumber, pageSize, searchText]);

  const handleNextPage = () => {
    if (pageNumber * pageSize < data.count) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <div className="">
        <div className="row m-0 py-4">
          <div className="col-12 col-lg-4">
            <h1 className="fs-3 m-0 fw-semibold lh-lg">Game History</h1>
          </div>
          <div className="col-12 col-lg-4">
            <div className="dashboard-inputs">
              <img src={Search} alt="Search" />
              <input
                type="text"
                className="py-2 px-3"
                placeholder="Enter History Name....."
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
        </div>
        {data?.data !== "" && data?.data != "" ? (
          <div className="my-course-data rounded-4 overflow-hidden bg-white">
            <div className="overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>Golf club name</th>
                    <th>Game Host</th>
                    <th>Course Name</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() =>
                          navigate(`/gamehistoryscoreboard/${item.id}`)
                        }
                        className="cursor-pointer"
                      >
                        <td>{item.golfClubName ? item.golfClubName : "--"}</td>
                        <td>{item.name ? item.name : "--"}</td>
                        <td>{item.courseName ? item.courseName : "--"}</td>
                        <td>
                          {item.createdAt
                            ? moment(item.createdAt).format("DD/MM/YYYY")
                            : "--"}
                        </td>
                        <td>
                          {item.createdAt
                            ? moment(item.createdAt).format("hh:mm A")
                            : "--"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              data={data}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              totalRows={data.count}
            />
          </div>
        ) : (
          <div className="my-course-data rounded-4 overflow-hidden bg-white px-3 py-3">
            No Data Available
          </div>
        )}
      </div>
    </>
  );
};

export default GameHistory;
