import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Plus from "../../../../../assets/images/icon/plus.svg";
import Edits from "../../../../../assets/images/icon/edit-bold.svg";
import AddDackCard from "./AddDackCard";
import useDialog from "../../../../../hooks/useDialog";

const AddDeck = (props) => {
  const {
    show,
    onHide,
    formik,
    cards,
    handleOpen,
    closeFirstDialog,
    handleEditDeckOpen,
    handleEditOpen,
    editOpen,
    handleEditClose,
    clickedCardIndex,
    setClickedCardIndex,
    cancel,
    selectedData,
    setDropdownTitle,
    dropdownTitle,
    titleDropdown,
    setSelectedData,
    handleDrinkingChange,
    involveDrink,
    changeMinPlayer,
    minActive,
    playersActive,
    submit,
    changePlayersToTarget,
    setAddNewDeck,
    update,
  } = props;

  return (
    <>
      <div className="add-courses my-desk">
        <Modal
          {...props}
          centered
          show={show}
          backdrop="static"
          keyboard={false}
          className="rounded-5 my-desk-modal"
        >
          <Modal.Header
            closeButton
            onClick={closeFirstDialog}
            className="card-title py-2"
          >
            <Modal.Title>Add Courses</Modal.Title>
          </Modal.Header>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-3 pb-0 position-sticky bg-white">
              <div className="titels text-start mb-4">
                <span className="text-white">Deck Name :</span>
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="dailog-card py-4">
                <div className="mb-5">
                  {formik.values.cards.map((item, index) => {
                    return (
                      <>
                        {item.title !== "" && (
                          <div
                            className="position-sticky d-flex flex-column gap-4 mb-4"
                            key={index}
                          >
                            <div className="input-field d-flex justify-content-between align-items-center">
                              <span className="text-white">{item.title}</span>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleEditDeckOpen(index);
                                }}
                              >
                                <img src={Edits} alt="Edit" />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                {/* Add Button */}
                <div className="position-sticky justify-content-center d-flex">
                  <button
                    className="add-course hole btns"
                    type="button"
                    onClick={() => {
                      handleEditOpen();
                      onHide();
                      setAddNewDeck(true);
                      formik.setFieldValue("cards", [
                        ...formik.values.cards,
                        {
                          involvesDrinking: true,
                          minPlayer: 0,
                          targetPlayer: 0,
                          text: "",
                          title: "",
                          type: "",
                        },
                      ]);
                    }}
                  >
                    <span>
                      <img src={Plus} alt="Pluse" />
                    </span>
                    Add New Cards
                  </button>
                </div>
              </div>
            </div>
            <Modal.Footer className="border-0 gap-3 px-3 py-4 justify-content-center">
              <button
                className="btns-d btns cancel"
                type="button"
                onClick={closeFirstDialog}
              >
                Cancel
              </button>
              <button className="btns-d btns add" type="submit">
                {update ? "Update" : "Add"}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      <AddDackCard
        editOpen={editOpen}
        handleEditClose={handleEditClose}
        formik={formik}
        cards={cards}
        handleOpen={handleOpen}
        clickedCardIndex={clickedCardIndex}
        setClickedCardIndex={setClickedCardIndex}
        cancel={cancel}
        selectedData={selectedData}
        setDropdownTitle={setDropdownTitle}
        dropdownTitle={dropdownTitle}
        titleDropdown={titleDropdown}
        setSelectedData={setSelectedData}
        handleDrinkingChange={handleDrinkingChange}
        involveDrink={involveDrink}
        changeMinPlayer={changeMinPlayer}
        minActive={minActive}
        playersActive={playersActive}
        submit={submit}
        changePlayersToTarget={changePlayersToTarget}
      />
    </>
  );
};

export default AddDeck;
