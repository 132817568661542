import serverCall from "../serverCall";
import { get } from "lodash";

const login = async (credential) => {
  const { email, password } = credential;
  const response = await serverCall.post(
    `/user/sign-in?email=${email}&password=${password}`
  );

  const data = get(response, "data.apiresponse.data.token", null);
  const Email = get(response, "data.apiresponse.data.email", null);
  const User = get(response, "data.apiresponse.data.userType", null);

  if (data && Email) {
    const mulliganStorage = {
      token: data,
      email: Email,
      isLoggedIn: true,
      user: User,
    };
    localStorage.setItem("mulligan-storage", JSON.stringify(mulliganStorage));
  }

  return response;
};

const signUp = async (body) => {
  const response = await serverCall.post(`/user/sign-up`, body);
  return response;
};

const verifyOTP = async (email, OTP, token, path) => {
  const response = await serverCall.post(
    `/user/verifyRegemail?email=${email}&otp=${OTP}`,
    null,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );

  if (path === "register") {
    const data = get(response, "data.apiresponse.data.token", null);
    const Email = get(response, "data.apiresponse.data.email", null);

    if (data && Email) {
      const mulliganStorage = {
        token: data,
        email: Email,
        isLoggedIn: true,
      };
      localStorage.setItem("mulligan-storage", JSON.stringify(mulliganStorage));
    }
  }
  return response;
};

const verifyForgotOTP = async (email, OTP, token, path) => {
  const response = await serverCall.post(
    `/user/forgotPassVerify?email=${email}&otp=${OTP}`,
    null,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const resendOTP = async (email) => {
  const response = await serverCall.post(`/user/resendOTP?email=${email}`);
  return response;
};

const forgotPasword = async (email, token) => {
  const response = await serverCall.post(
    `/user/sendForgetPasswordOTP/${email}`,
    null,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const setNewPassword = async (password, token) => {
  const response = await serverCall.post(
    `/user/createPassword?password=${password}`,
    null,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const changeForgotPassword = async (email, password, token) => {
  const response = await serverCall.post(
    `/user/changeForgotPassword?email=${email}&password=${password}`,
    null,
    {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const logout = () => {
  localStorage.clear();
};

const AuthServices = {
  login,
  signUp,
  logout,
  verifyOTP,
  forgotPasword,
  setNewPassword,
  resendOTP,
  verifyForgotOTP,
  changeForgotPassword,
};

export default AuthServices;
