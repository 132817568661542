import serverCall from "../serverCall";

const checkSubscription = async () => {
  const response = await serverCall.get(`/user/checkSubscription`);
  return response;
};

const getAllPlans = async () => {
  const response = await serverCall.post(`/payment/getPlans`);
  return response;
};

const cancelSubscription = async () => {
  const response = await serverCall.get(`/payment/cancelSubscription`);
  return response;
};
const createSubscription = async (planType, amount) => {
  const response = await serverCall.get(
    `/payment/create?amount=${amount}&planType=${planType}`
  );
  return response;
};

const SubsriptionServices = {
  checkSubscription,
  getAllPlans,
  cancelSubscription,
  createSubscription,
};

export default SubsriptionServices;
