import serverCall from "../serverCall";

const getUserDetails = async () => {
  const response = await serverCall.get(`/user/getUserDetails`);
  return response;
};

const updateUserDetails = async (body) => {
  const response = await serverCall.post(`/user/update`, body);
  return response;
};

const updateEmail = async (email) => {
  const response = await serverCall.post(`/user/changeEmail?newEmail=${email}`);
  return response;
};

const UserServices = {
  getUserDetails,
  updateUserDetails,
  updateEmail,
};

export default UserServices;
