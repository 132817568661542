import React from "react";
import { Modal } from "react-bootstrap";
import useDialog from "../../../../../hooks/useDialog";
import EditCoursePars from "./EditCoursePars";
import Arrows from "../../../../../assets/images/icon/arrow-bold.svg";

const AddCourse = (props) => {
  const {
    show,
    onHide,
    handleOpen,
    courseActive,
    formik,
    holes,
    addHole,
    removeHole,
    clearHoles,
    decreaseHoles,
    increaseHoles,
    editOpen,
    handleEditOpen,
    handleEditClose,
    handlePrev,
    handleNext,
    handleZIP,
    submit,
  } = props;

  return (
    <>
      <div className="add-courses">
        <Modal
          {...props}
          centered
          show={show}
          backdrop="static"
          keyboard={false}
          className="rounded-5"
        >
          <Modal.Header
            closeButton
            onClick={onHide}
            className="card-title py-2"
          >
            <Modal.Title>Add Courses</Modal.Title>
          </Modal.Header>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-3 position-sticky bg-white">
              <div className="dailog-card border-color">
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">Golf Club Name :</span>
                    <input
                      type="text"
                      name="golfClubName"
                      value={formik.values.golfClubName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.golfClubName &&
                    formik.errors.golfClubName && (
                      <div className="error-text">
                        {formik.errors.golfClubName}
                      </div>
                    )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">Course name :</span>
                    <input
                      type="text"
                      name="courseName"
                      value={formik.values.courseName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.courseName && formik.errors.courseName && (
                    <div className="error-text">{formik.errors.courseName}</div>
                  )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">Country :</span>
                    <input
                      type="text"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    />
                  </div>{" "}
                  {formik.touched.country && formik.errors.country && (
                    <div className="error-text">{formik.errors.country}</div>
                  )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">State :</span>
                    <input
                      type="text"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                    />
                  </div>{" "}
                  {formik.touched.state && formik.errors.state && (
                    <div className="error-text">{formik.errors.state}</div>
                  )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">City :</span>
                    <input
                      type="text"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.city && formik.errors.city && (
                    <div className="error-text">{formik.errors.city}</div>
                  )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">ZIP :</span>
                    <input
                      type="number"
                      name="zip"
                      value={formik.values.zip}
                      onChange={handleZIP}
                    />
                  </div>
                  {formik.touched.zip && formik.errors.zip && (
                    <div className="error-text">{formik.errors.zip}</div>
                  )}
                </div>
                <div className="position-sticky mb-3">
                  <div className="input-field">
                    <span className="text-white">In This a Real Course ?</span>
                    <div
                      className="hole-plus p-0 "
                      onClick={() => handlePrev()}
                    >
                      <img src={Arrows} alt="Arrow" />
                      <span className="yes-no"> {courseActive} </span>
                      <img
                        src={Arrows}
                        alt="Arrow"
                        onClick={() => handleNext()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-0 gap-3 px-3 m-0 pt-1 pb-2">
              <button
                className="btns border-0 w-100 add fw-semibold edit position-relative"
                onClick={() => {
                  // onHide();
                  handleEditOpen();
                }}
                type="button"
              >
                Edit COURSE PARS
              </button>
            </div>
            <Modal.Footer className="border-0 gap-3 px-3">
              <button
                className="btns-d btns cancel"
                type="button"
                onClick={onHide}
              >
                Cancel
              </button>
              <button className="btns-d btns add" type="submit">
                Save
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <EditCoursePars
          editOpen={editOpen}
          handleEditClose={handleEditClose}
          formik={formik}
          holes={holes}
          addHole={addHole}
          removeHole={removeHole}
          clearHoles={clearHoles}
          decreaseHoles={decreaseHoles}
          increaseHoles={increaseHoles}
          submit={submit}
          handleOpen={handleOpen}
        />
      </div>
    </>
  );
};

export default AddCourse;
