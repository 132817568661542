import serverCall from "../serverCall";

const getAllDecks = async (pageNumber, pageSize, searchText) => {
  if (searchText) {
    const response = await serverCall.get(
      `/deck/web/getAll?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`
    );
    return response;
  } else {
    const response = await serverCall.get(
      `/deck/web/getAll?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return response;
  }
};

const createDeck = async (body) => {
  const response = await serverCall.post(`/deck/system/create`, body);
  return response;
};

const updateDeck = async (id, body) => {
  const response = await serverCall.post(`/deck/update/${id}`, body);
  return response;
};

const getDeckDetails = async (id) => {
  const response = await serverCall.get(`/deck/${id}`);
  return response;
};
const deleteDeck = async (deckID) => {
  const response = await serverCall.delete(`/deck/${deckID}`);
  return response;
};

const DeckServices = {
  getAllDecks,
  createDeck,
  getDeckDetails,
  deleteDeck,
  updateDeck,
};

export default DeckServices;
