import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import MatxLayout from "../matxLayout";
import Home from "../components/page/home/Home";
import Dashboard from "../components/page/dashboard/Dashboard";
import Register from "../components/authentication/Register";
import Login from "../components/authentication/Login";
import VerifyOTP from "../components/authentication/VerifyOTP";
import ForgotPassword from "../components/authentication/ForgotPassword";
import SetNewPassword from "../components/authentication/SetNewPassword";
import GameHistoryScoreboard from "../components/page/dashboard/dashboardTab/gameHistory/GameHistoryScoreboard";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/home" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/gamehistoryscoreboard/:id",
        element: <GameHistoryScoreboard />,
      },
    ],
  },
  {
    path: "/home",
    element: !isLoggedIn ? <Home /> : <RedirectionWrapper to="/dashboard" />,
  },
  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <RedirectionWrapper to="/dashboard" />,
  },
  {
    path: "/register",
    element: !isLoggedIn ? (
      <Register />
    ) : (
      <RedirectionWrapper to="/dashboard" />
    ),
  },
  {
    path: "/verify-otp",
    element: !isLoggedIn ? (
      <VerifyOTP />
    ) : (
      <RedirectionWrapper to="/dashboard" />
    ),
  },
  {
    path: "/forgot-password",
    element: !isLoggedIn ? (
      <ForgotPassword />
    ) : (
      <RedirectionWrapper to="/dashboard" />
    ),
  },
  {
    path: "/setNewPassword",
    element: !isLoggedIn ? (
      <SetNewPassword />
    ) : (
      <RedirectionWrapper to="/dashboard" />
    ),
  },
  // { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
