import React from "react";
import Logo from "../../assets/images/logo/Logo.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer py-5">
        <div className="container">
          <div className="row m-0 align-items-center justify-content-lg-between justify-content-center">
            <div className="col-4">
              <a href="/">
                <img src={Logo} className="" alt="Logo" />
              </a>
            </div>
            <div className="col-md-4 col-8 p-0 d-flex align-items-center justify-content-md-center justify-content-end gap-3 mb-lg-0">
              <button
                className="register"
                style={{ background: "#F8F4EA" }}
                onClick={() => navigate("/register")}
              >
                Register
              </button>
              <button className="login" onClick={() => navigate("/login")}>
                Login
              </button>
            </div>
            <div className="col-md-4 col-12 mt-4 mt-md-0">
              <p className="text-center m-0">Copyright Mulligan All Rights.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
