import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Arrows from "../../../../../assets/images/icon/arrow-bold.svg";

const AddDackCard = (props) => {
  const dropdownRef = useRef();
  const {
    handleEditClose,
    editOpen,
    formik,
    cards,
    handleOpen,
    clickedCardIndex,
    setClickedCardIndex,
    cancel,
    selectedData,
    setDropdownTitle,
    dropdownTitle,
    titleDropdown,
    setSelectedData,
    handleDrinkingChange,
    involveDrink,
    changeMinPlayer,
    minActive,
    playersActive,
    submit,
    changePlayersToTarget,
  } = props;

  return (
    <>
      <div className="add-courses">
        <Modal
          centered
          {...props}
          show={editOpen}
          backdrop="static"
          keyboard={false}
          className="rounded-5"
        >
          <Modal.Header
            closeButton
            onClick={cancel}
            className="card-title py-2"
          >
            <Modal.Title>Add Courses</Modal.Title>
          </Modal.Header>
          <form>
            {[...Array(formik.values.cards)].map((_, index) => {
              const lastIndex = formik.values.cards.length - 1;

              return (
                <>
                  <div className="p-3 pb-0 position-sticky bg-white">
                    <div className="titels text-start mb-4">
                      <span className="text-white">Deck Name :</span>
                      <input
                        type="text"
                        name={`cards[${
                          clickedCardIndex !== -1 ? clickedCardIndex : lastIndex
                        }].title`}
                        value={
                          formik.values.cards[
                            clickedCardIndex !== -1
                              ? clickedCardIndex
                              : lastIndex
                          ]?.title
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="px-sm-3 px-1 position-sticky bg-white">
                    <div className="dailog-card border-color d-flex flex-column gap-3">
                      <div className="position-sticky">
                        <div className="input-field gap-3 align-items-center">
                          <span className="text-white">Card Type : </span>
                          <div className="hole-plus p-0 ">
                            <p className="m-0 text-white fs-6">
                              {Array.isArray(selectedData)
                                ? selectedData[
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  ]
                                : selectedData}
                            </p>
                            <img
                              src={Arrows}
                              alt="Arrow"
                              style={{ rotate: "-90deg" }}
                              onClick={() => {
                                setDropdownTitle(!dropdownTitle);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {dropdownTitle && (
                        <div className="" ref={dropdownRef}>
                          <ul className="suggestion-list overflow-y-auto">
                            <div className="h-[100%] overflow-y-auto">
                              {titleDropdown?.map((res) => {
                                return (
                                  <>
                                    <div
                                      key={
                                        clickedCardIndex !== -1
                                          ? clickedCardIndex
                                          : lastIndex
                                      }
                                      className="ps-1 dropdown-hover-class"
                                      onClick={() => {
                                        formik.setFieldValue(
                                          `cards[${
                                            clickedCardIndex !== -1
                                              ? clickedCardIndex
                                              : lastIndex
                                          }].type`,
                                          res.label
                                        );
                                        setSelectedData(res.label);
                                        setDropdownTitle(false);
                                      }}
                                    >
                                      <li className="flex cursor-pointer ps-3 py-2">
                                        <div>{res.label}</div>
                                      </li>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </ul>
                        </div>
                      )}
                      <div className="position-sticky">
                        <div className="input-field">
                          <textarea
                            type="text"
                            placeholder="Card Text"
                            name={`cards[${
                              clickedCardIndex !== -1
                                ? clickedCardIndex
                                : lastIndex
                            }].text`}
                            value={
                              formik.values.cards[
                                clickedCardIndex !== -1
                                  ? clickedCardIndex
                                  : lastIndex
                              ]?.text
                            }
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="position-sticky">
                        <div className="input-field gap-2">
                          <span className="text-white me-sm-5">
                            Involves Drinking ?
                          </span>
                          <div className="hole-plus p-0 ">
                            <img
                              src={Arrows}
                              alt="Arrow"
                              onClick={() =>
                                handleDrinkingChange(
                                  clickedCardIndex !== -1
                                    ? clickedCardIndex
                                    : lastIndex
                                )
                              }
                            />
                            <span>{involveDrink}</span>
                            <img
                              src={Arrows}
                              alt="Arrow"
                              onClick={() =>
                                handleDrinkingChange(
                                  clickedCardIndex !== -1
                                    ? clickedCardIndex
                                    : lastIndex
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="position-sticky">
                        <div className="input-field align-items-center">
                          <span className="text-white">Minimum Player</span>
                          <div className="position-sticky d-flex justify-content-center align-items-center w-100">
                            <div className="d-flex justify-content-center align-items-center w-100 gap-2 players-select">
                              <span
                                onClick={() =>
                                  changeMinPlayer(
                                    2,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  minActive === 2 ? "active cursor-pointer" : ""
                                }`}
                              >
                                2
                              </span>
                              <span
                                onClick={() =>
                                  changeMinPlayer(
                                    3,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  minActive === 3 ? "active cursor-pointer" : ""
                                }`}
                              >
                                3
                              </span>
                              <span
                                onClick={() =>
                                  changeMinPlayer(
                                    4,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  minActive === 4 ? "active cursor-pointer" : ""
                                }`}
                              >
                                4
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="position-sticky">
                        <div className="input-field align-items-center">
                          <span className="text-white">Player's to Target</span>
                          <div className="position-sticky d-flex justify-content-center align-items-center w-100">
                            <div className="d-flex justify-content-center align-items-center w-100 gap-2 players-select">
                              <span
                                onClick={() =>
                                  changePlayersToTarget(
                                    0,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  playersActive === 0
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                              >
                                0
                              </span>
                              <span
                                onClick={() =>
                                  changePlayersToTarget(
                                    1,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  playersActive === 1
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                              >
                                1
                              </span>
                              <span
                                onClick={() =>
                                  changePlayersToTarget(
                                    2,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  playersActive === 2
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                              >
                                2
                              </span>
                              <span
                                onClick={() =>
                                  changePlayersToTarget(
                                    3,
                                    clickedCardIndex !== -1
                                      ? clickedCardIndex
                                      : lastIndex
                                  )
                                }
                                className={`cursor-pointer ${
                                  playersActive === 3
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                              >
                                3
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Modal.Footer className="border-0 gap-3 px-3 py-4 justify-content-center">
              <button
                className="btns-d btns cancel"
                type="button"
                onClick={cancel}
              >
                Cancel
              </button>
              <button
                className="btns-d btns add"
                type="button"
                onClick={submit}
              >
                Add
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default AddDackCard;
