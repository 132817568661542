import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = ({
  pageSize,
  setPageSize,
  pageNumber,
  data,
  handlePrevPage,
  handleNextPage,
  totalRows,
}) => {
  const startIndex = (pageNumber - 1) * pageSize + 1;
  const endIndex = Math.min(pageNumber * pageSize, totalRows);

  return (
    <div>
      {" "}
      <div className="w-100 d-flex justify-content-end p-3 pagination-part">
        <div className="d-flex gap-3 flex-column-reverse flex-sm-row">
          <div className="d-flex gap-2 align-items-center">
            <span className="fw-semibold">Rows per page:</span>
            <select
              className="pagination"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="d-flex align-items-center gap-3">
            <span>
              {startIndex}-{endIndex} of {totalRows}
            </span>
            <div className="d-flex align-items-center gap-2">
              <div
                onClick={handlePrevPage}
                className={` ${
                  pageNumber > 1 ? "paginaion-button-hover" : "paginaion-button"
                }`}
              >
                <FaChevronLeft
                  className={`${pageNumber > 1 ? "" : "disable"}`}
                />
              </div>
              <div
                onClick={handleNextPage}
                className={`${
                  pageNumber * pageSize < totalRows
                    ? "paginaion-button-hover"
                    : "paginaion-button"
                }`}
              >
                <FaChevronRight
                  className={`${
                    pageNumber * pageSize < totalRows ? "" : "disable"
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
