import React, { useState } from "react";

const useDialog = () => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  return {
    open,
    handleOpen,
    handleClose,
    editOpen,
    handleEditOpen,
    handleEditClose,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
  };
};

export default useDialog;
