import React from "react";
import Header from "../../header/Header";
import Hero from "../../../assets/images/background/hero.svg";
import Video from "../../../assets/images/background/hero-v.svg";
import Play from "../../../assets/images/icon/play.svg";
import First from "../../../assets/images/background/experience-one.png";
import Two from "../../../assets/images/background/experience-two.png";
import Three from "../../../assets/images/background/experience-three.png";
import Four from "../../../assets/images/background/experience-four.png";
import Goal from "../../../assets/images/background/goal.svg";
import User from "../../../assets/images/background/user.svg";
import Footer from "../../footer/page";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Hero Section */}
      <Header />
      <div className="home-section">
        <div className="container">
          <div className="hero-main">
            <div className="row m-0 py-5 border-top hero-content-bg">
              <h1 className="col-12 col-lg-9 m-0">
                With a rich history spanning decades, our club has become a
                symbol of excellence in the golfing community.
              </h1>
            </div>
            <div className="hero-video">
              <div className="position-relative pb-5">
                <img src={Video} alt="Video" />
                {/* <div className="video-play">
                  <img src={Play} alt="Play" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Experience Section */}
      <div className="experience-section">
        <div className="container">
          <div className="experience-titles">
            <h5 className="pt-4">USER EXPERIENCE</h5>
            <h1>
              Experience the Golf trip of your dreams with us
              <br /> Royal Golf Club
            </h1>
          </div>
          <div>
            <div className="row m-0 align-items-center">
              <div className="col-12 col-lg-5 d-flex ps-0 pe-lg-5 justify-content-center">
                <img src={First} alt="Exp-One" />
              </div>
              <div className="col-12 col-xxl-4 col-lg-6 py-4 py-lg-0 text-center text-lg-end">
                <h1 className="text-left">Lorem Ipsum dolor sitiament</h1>
                <p className="">
                  Uneekor Simulators indoor golf practice has never been better.
                  People can came over there and take booking simulators for
                  hours.
                </p>
              </div>
            </div>
            <div className="row m-0 align-items-center">
              <div className="col-12 col-xxl-4 col-lg-6 offset-xl-3 text-center text-lg-end py-4 py-lg-0  order-2 order-lg-1">
                <h1>Lorem Ipsum dolor sitiament</h1>
                <p>
                  Uneekor Simulators indoor golf practice has never been better.
                  People can came over there and take booking simulators for
                  hours.
                </p>
              </div>
              <div className="col-12 col-lg-5 pe-0 d-flex justify-content-lg-end justify-content-center order-1 order-lg-2">
                <img src={Two} alt="Exp-two" />
              </div>
            </div>
            <div className="row m-0 align-items-center">
              <div className="col-12 col-lg-5 d-flex ps-0 pe-lg-5 justify-content-center">
                <img src={Three} alt="Exp-three" />
              </div>
              <div className="col-xxl-4 col-lg-6 py-4 py-lg-0 text-center text-lg-end">
                <h1 className="text-left">Lorem Ipsum dolor sitiament</h1>
                <p className="">
                  Uneekor Simulators indoor golf practice has never been better.
                  People can came over there and take booking simulators for
                  hours.
                </p>
              </div>
            </div>
            <div className="row m-0 align-items-center">
              <div className="col-12 col-xxl-4 col-lg-6 offset-xl-3 text-center text-lg-end py-4 py-lg-0  order-2 order-lg-1">
                <h1>Lorem Ipsum dolor sitiament</h1>
                <p>
                  Uneekor Simulators indoor golf practice has never been better.
                  People can came over there and take booking simulators for
                  hours.
                </p>
              </div>
              <div className="col-12 col-lg-5 pe-0 d-flex justify-content-lg-end justify-content-center order-1 order-lg-2">
                <img src={Four} alt="Exp-two" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Goals */}
      <div className="row m-0 align-items-center goal-section">
        <div className="col-lg-8 col-xl-6 ps-0 order-lg-1 order-2">
          <img src={Goal} alt="Goal" />
        </div>
        <div className="col-lg-4 col-xl-6 col-xxl-5 flex-column d-flex gap-3 py-lg-0 order-lg-2 order-1">
          <h3>WE ARE</h3>
          <p>
            With 5 top of the line Uneekor Simulators indoor golf practice has
            never been better. Each simulator is equipped with GSPro software
            that has 300 of the top courses in the world, as well as many local
            courses. Each simulator has several practice softwares that can
            record live club head videos with playback, and accurately displays
            all the data to take your game to the next level!
          </p>
          <div onClick={() => navigate("/login")}>
            <button className="booking-button">Take A booking</button>
          </div>
        </div>
      </div>

      {/* Testimonial */}
      <div className="testimonial-section">
        <div className="container">
          <h6>OUR TESTIMONIALS</h6>
          <p>Client Voices Our Testimonials Speak Volumes</p>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide className="d-flex flex-lg-column flex-column-reverse gap-4 align-items-start p-0">
              <p className="text-start">
                “With a rich history spanning decades, our club has become a
                symbol of excellence in the golfing community. With a rich
                history spanning decades, our club has become a symbol of
                excellence in the golfing community”
              </p>
              <div className="d-flex mt-5">
                <div className="testi-user">
                  <img className="h-75 w-75" src={User} alt="User" />
                </div>
                <div>
                  <h6 className="fs-6 mb-1 text-start">Cameron Williamson</h6>
                  <p className="fs-6 mb-1 text-start">Team Mamber</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-lg-column flex-column-reverse gap-4 align-items-start p-0">
              <p className="text-start">
                “With a rich history spanning decades, our club has become a
                symbol of excellence in the golfing community. With a rich
                history spanning decades, our club has become a symbol of
                excellence in the golfing community”
              </p>
              <div className="d-flex mt-5">
                <div className="testi-user">
                  <img className="h-75 w-75" src={User} alt="User" />
                </div>
                <div>
                  <h6 className="fs-6 mb-1 text-start">Cameron Williamson</h6>
                  <p className="fs-6 mb-1 text-start">Team Mamber</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="d-flex flex-lg-column flex-column-reverse gap-4 align-items-start p-0">
              <p className="text-start">
                “With a rich history spanning decades, our club has become a
                symbol of excellence in the golfing community. With a rich
                history spanning decades, our club has become a symbol of
                excellence in the golfing community”
              </p>
              <div className="d-flex mt-5">
                <div className="testi-user">
                  <img className="h-75 w-75" src={User} alt="User" />
                </div>
                <div>
                  <h6 className="fs-6 mb-1 text-start">Cameron Williamson</h6>
                  <p className="fs-6 mb-1 text-start">Team Mamber</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div></div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
