import React, { useState } from "react";
import Logo from "../../assets/images/logo/Logo.svg";
import { useNavigate } from "react-router-dom";
import useDialog from "../../hooks/useDialog";
import ManageSubscription from "../page/dashboard/dashboardTab/myAccount/ManageSubscription";
import * as yup from "yup";
import HavePromoCode from "../page/dashboard/dashboardTab/promoCode/HavePromocode";
import { useFormik } from "formik";

const Header = () => {
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useDialog();
  const [isOpen, setIsOpen] = useState(false);
  const mulliganStorageString = localStorage.getItem("mulligan-storage");
  const mulliganStorage = JSON.parse(mulliganStorageString);
  const isLoggedIn = mulliganStorage?.isLoggedIn;

  const handlePromoCOdeClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <nav className="header">
        <div className="container">
          <div className="d-flex gap-3 justify-content-between align-items-center">
            <div className="">
              <a href="/">
                <img src={Logo} className="logo" alt="Logo" />
              </a>
            </div>
            {!isLoggedIn ? (
              <div className="d-flex gap-3">
                <div
                  className="position-sticky"
                  onClick={() => navigate("/register")}
                >
                  <button className="register">Register</button>
                </div>
                <div onClick={() => navigate("/login")}>
                  <button className="login">Login</button>
                </div>
              </div>
            ) : (
              <div
                className="position-sticky d-lg-block d-none"
                onClick={() => setIsOpen(true)}
              >
                <button className="register">Credits</button>
              </div>
            )}
          </div>
        </div>
      </nav>
      {open && (
        <ManageSubscription
          show={open}
          onHide={handleClose}
          handlePromoCOdeClose={handlePromoCOdeClose}
        />
      )}
      {isOpen && (
        <HavePromoCode
          show={isOpen}
          onHide={handlePromoCOdeClose}
          handleOpen={handleOpen}
        />
      )}
    </>
  );
};

export default Header;
