import React, { Fragment, useEffect, useState } from "react";
import Plus from "../../../../../assets/images/icon/plus.svg";
import Edit from "../../../../../assets/images/icon/edit.svg";
import Delete from "../../../../../assets/images/icon/delete.svg";
import useDialog from "../../../../../hooks/useDialog";
import AddCourse from "./AddCourse";
import DeleteAll from "./DeleteAll";
import CourseServices from "../../../../../services/courses.service";
import Search from "../../../../../assets/images/icon/Search.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Pagination from "../../Pagination";
import { enqueueSnackbar } from "notistack";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";

// Initial valu for form
const initialValue = {
  golfClubName: "",
  courseName: "",
  country: "",
  city: "",
  zip: "",
  isReal: true,
  holes: [
    {
      index: 0,
      par: 0,
    },
  ],
  state: "",
};

const holesSchema = yup.object().shape({
  par: yup.mixed().required("Please Enter Par!"),
});

// validation for form
const validationSchema = yup.object().shape({
  golfClubName: yup.string().required("Please Enter Golf Club Name!"),
  courseName: yup.string().required("Please Enter Course Name!"),
  country: yup.string().required("Please Enter Country!"),
  state: yup.string().required("Please Enter State!"),
  city: yup.string().required("Please Enter City!"),
  zip: yup.number().required("Please Enter ZIP!"),
  holes: yup.array().of(holesSchema),
});

const MyCourses = () => {
  const {
    open,
    handleOpen,
    handleClose,
    deleteOpen,
    handleDeleteOpen,
    handleDeleteClose,
  } = useDialog();

  const { editOpen, handleEditOpen, handleEditClose } = useDialog();

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [courseActive, setCourseActive] = useState("Yes");
  const [deleteID, setDeleteID] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [holes, setHoles] = useState([
    {
      index: 1,
      par: 1,
    },
  ]);

  console.log("holes", holes);

  const clearHoles = () => {
    handleEditClose();
    handleOpen();
    setHoles(formik.values.holes);
  };

  const addHole = () => {
    setHoles((prevHoles) => [
      ...prevHoles,
      {
        index: prevHoles.length + 1,
        par: 0,
      },
    ]);
  };

  const removeHole = (index) => {
    setHoles((prevHoles) => prevHoles.filter((_, i) => i !== index));
  };

  const submit = () => {
    formik.setFieldValue("holes", holes);
    handleEditClose();
    handleOpen();
  };

  const decreaseHoles = (index) => {
    setHoles((prevHoles) =>
      prevHoles.map((hole, i) =>
        i === index ? { ...hole, par: hole.par - 1 } : hole
      )
    );
  };

  const increaseHoles = (index) => {
    setHoles((prevHoles) =>
      prevHoles?.map((hole, i) =>
        i === index ? { ...hole, par: hole.par + 1 } : hole
      )
    );
  };

  const getAllCourseData = async () => {
    try {
      const response = await CourseServices.getAllCourses(
        pageNumber,
        pageSize,
        searchText
      );
      setData(response.data.apiresponse.data.data);
      setTotalRows(response.data.apiresponse.data.count);
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error.response?.data, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    getAllCourseData();
  }, [searchText, pageNumber, pageSize]);

  const handleFormSubmit = async (values) => {
    try {
      if (!values.id) {
        const response = await CourseServices.createCourse(values);
        getAllCourseData();
        handleClose();
        formik.resetForm();
        setHoles([
          {
            index: 0,
            par: 0,
          },
        ]);
      } else {
        const response = await CourseServices.updateCourse(values.id, values);
        getAllCourseData();
        handleClose();
        formik.resetForm();
        setHoles([
          {
            index: 0,
            par: 0,
          },
        ]);
      }
    } catch (error) {
      console.log("e", error);
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const deleteCourse = async (id) => {
    handleDeleteOpen();
    setDeleteID(id);
  };

  const finalDeleteButton = async (id) => {
    try {
      await CourseServices.deleteCourse(id);
      getAllCourseData();
      handleDeleteClose();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleDialogOpen = async (id) => {
    handleOpen();
    if (id) {
      try {
        const response = await CourseServices.getCourseDetails(id);
        const result = response.data.apiresponse.data;
        formik.setFieldValue("id", result.id);
        formik.setFieldValue("city", result.city);
        formik.setFieldValue("country", result.country);
        formik.setFieldValue("courseName", result.courseName);
        formik.setFieldValue("golfClubName", result.golfClubName);
        formik.setFieldValue("holes", result.holes);
        formik.setFieldValue("state", result.state);
        formik.setFieldValue("isReal", result.real);
        formik.setFieldValue("zip", result.zip);
        formik.setFieldValue("isReal", result.real === false ? false : true);
        setCourseActive(result.real === false ? "No" : "Yes");
        setHoles(result.holes);
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    } else {
      formik.resetForm();
    }
  };

  const closeDialog = () => {
    handleClose();
    formik.resetForm();
    setHoles([
      {
        index: 0,
        par: 0,
      },
    ]);
  };

  const handlePrev = () => {
    setCourseActive(courseActive === "Yes" ? "No" : "Yes");
    formik.setFieldValue("isReal", courseActive === "Yes" ? false : true);
  };
  const handleNext = () => {
    setCourseActive(courseActive === "Yes" ? "No" : "Yes");
    formik.setFieldValue("isReal", courseActive === "Yes" ? false : true);
  };

  const handleZIP = (code) => {
    formik.setFieldValue("zip", String(code.target.value));
  };

  const handleNextPage = () => {
    if (pageNumber * pageSize < totalRows) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <div className="">
        <div className="row m-0 py-4">
          <div className="col-12 col-lg-4">
            <h1 className="fs-3 m-0 fw-semibold lh-lg">My Courses</h1>
          </div>
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <div className="dashboard-inputs">
              <img src={Search} alt="Search" />
              <input
                type="text"
                className="py-2 px-3"
                placeholder="Enter Course Name....."
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex  justify-content-end">
            <button
              className="add-course btns"
              onClick={() => handleDialogOpen("")}
            >
              <span>
                <img src={Plus} alt="Pluse" />
              </span>
              Add Course
            </button>
          </div>
        </div>

        {data !== "" && data != "" ? (
          <div className="my-course-data rounded-4 overflow-hidden bg-white">
            <div className="overflow-x-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>Club Name</th>
                    <th>Course Name</th>
                    <th>Holes</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.courseName}</td>
                        <td>{item.golfClubName}</td>
                        <td>{item.holeCount}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <div className="me-2">
                              <div
                                className="action-btn"
                                onClick={() => handleDialogOpen(item.id)}
                              >
                                <img
                                  src={Edit}
                                  alt="Edit"
                                  width={25}
                                  height={25}
                                />
                              </div>
                            </div>
                            <div className="me-2">
                              <div
                                className="action-btn"
                                onClick={() => deleteCourse(item.id)}
                              >
                                <img
                                  src={Delete}
                                  alt="Edit"
                                  width={25}
                                  height={25}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Pagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              data={data}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              totalRows={totalRows}
            />
          </div>
        ) : (
          <div className="my-course-data rounded-4 overflow-hidden bg-white px-3 py-3">
            No Data Available
          </div>
        )}
      </div>

      <AddCourse
        show={open}
        onHide={closeDialog}
        courseActive={courseActive}
        setCourseActive={setCourseActive}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        formik={formik}
        holes={holes}
        addHole={addHole}
        removeHole={removeHole}
        clearHoles={clearHoles}
        decreaseHoles={decreaseHoles}
        increaseHoles={increaseHoles}
        editOpen={editOpen}
        handleEditOpen={handleEditOpen}
        handleEditClose={handleEditClose}
        handlePrev={handlePrev}
        handleNext={handleNext}
        handleZIP={handleZIP}
        submit={submit}
        handleOpen={handleOpen}
      />
      <DeleteAll
        show={deleteOpen}
        onHide={handleDeleteClose}
        finalDeleteButton={finalDeleteButton}
        deleteID={deleteID}
        tab={"Course"}
      />
    </>
  );
};

export default MyCourses;
