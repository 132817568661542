export const CUSTOM_STYLES = {
  headCells: {
    style: {
      position: "sticky",
      right: 0,
      backgroundColor: "#00350C", // Set the background color for the last column
    },
  },
  cells: {
    style: {
      position: "sticky",
      right: 0,
    },
  },
  rows: {
    style: {
      marginTop: 12,
      marginBottom: 12,
      "&:nth-child(even)": {
        backgroundColor: "#FFFFFF", // Set the background color for odd rows
      },
      "&:nth-child(even) > :last-child": {
        backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
      },
    },
  },
};

export const SUCCESS = "success";
export const ERROR = "error";
export const ANCHORORIGIN = {
  horizontal: "right",
  vertical: "bottom",
};
