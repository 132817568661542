import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { ANCHORORIGIN, ERROR } from "../../utils/constants";
import { register } from "../../store/slice/auth";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    birthdate: 0,
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required!"),
    lastName: yup.string().required("Last Name is required!"),
    email: yup.string().required("Email is required!"),
    birthdate: yup.string().required("Birth Date is required"),
  });

  const handleFormSubmit = async (values) => {
    try {
      const response = await dispatch(register(values)).unwrap();

      navigate("/verify-otp", {
        state: {
          token: response,
          path: "register",
          email: values.email,
        },
      });
    } catch (e) {
      formik.resetForm();
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    } finally {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const handleDateChange = (date) => {
    formik.setFieldValue("birthdate", moment(date).valueOf());
  };

  return (
    <>
      <div className="login-forms">
        <div className="container">
          <div className="login-main position-sticky">
            <div className="title">Registration</div>
            <form onSubmit={formik.handleSubmit} className="my-4">
              <div className="user__details">
                <div className="input__box">
                  <span className="details">First Name</span>
                  <div className="input-field">
                    <input
                      type="text"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      placeholder="Enter First Name"
                      required
                    />
                  </div>
                </div>
                <div className="input__box">
                  <span className="details">Last Name</span>
                  <div className="input-field">
                    <input
                      type="text"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div className="input__box">
                  <span className="details">Email</span>
                  <div className="input-field">
                    <input
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="input__box">
                  <span className="details">birthdate</span>
                  <div className="input-field">
                    <DatePicker
                      selected={formik.values.birthdate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="input__box mb-5 font-600">
                <span className="details text-white">Password</span>
                <div className="input-field">
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Enter Password"
                  />
                </div>
              </div> */}
              <div>
                <button
                  className="login p-3 h-100 w-100 fw-semibold fs-6"
                  type="submit"
                >
                  Register
                </button>
              </div>
              <div className="forgot-password text-white mt-4 text-center">
                Already Have an account?{" "}
                <span onClick={() => navigate("/login")}>Login</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
