import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import Arrows from "../../../../../assets/images/icon/arrow-bold.svg";

const AddPromoCode = (props) => {
  const dropdownRef = useRef();
  const {
    show,
    onHide,
    selectedData,
    dropdownTitle,
    titleDropdown,
    setDropdownTitle,
    formik,
    setSelectedData,
    generateRandomPassword,
  } = props;

  return (
    <div className="add-courses">
      <Modal
        centered
        {...props}
        show={show}
        backdrop="static"
        keyboard={false}
        className="rounded-5"
      >
        <Modal.Header closeButton onClick={onHide} className="card-title py-2">
          <Modal.Title>Add Promo Code</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-3 bg-white subscription-modals">
            <div className="dailog-card py-4">
              <div className=" d-flex flex-column gap-2">
                <div className="position-sticky mb-3">
                  <div className="input-field gap-3 align-items-center">
                    <span className="text-white">Select Type : </span>
                    <div className="hole-plus p-0 ">
                      <p className="m-0 text-white fs-6">{selectedData}</p>
                      <img
                        src={Arrows}
                        alt="Arrow"
                        style={{ rotate: "-90deg" }}
                        onClick={() => {
                          setDropdownTitle(!dropdownTitle);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {dropdownTitle && (
                  <div className="" ref={dropdownRef}>
                    <ul className="promo-code-list overflow-y-auto">
                      <div className="h-[100%] overflow-y-auto">
                        {titleDropdown?.map((res, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="ps-1 dropdown-hover-class"
                                onClick={() => {
                                  formik.setFieldValue(
                                    `promocodeType`,
                                    res.label
                                  );
                                  setSelectedData(res.label);
                                  setDropdownTitle(false);
                                }}
                              >
                                <li className="flex cursor-pointer ps-3 py-2">
                                  <div>{res.label}</div>
                                </li>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </ul>
                  </div>
                )}
                <div className="position-sticky d-flex gap-2">
                  <div className="input-field">
                    <input
                      type="text"
                      placeholder="Promo Code"
                      name={`promocode`}
                      value={formik.values.promocode}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </div>
                  <button
                    className="add-promo btns"
                    type="button"
                    onClick={generateRandomPassword}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="border-0 gap-3 px-3 m-0 pt-1 pb-2">
            <div className="">
              <button
                className="btns border-0 w-100 add fw-semibold edit position-relative"
                type="submit"
              >
                Generate
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddPromoCode;
