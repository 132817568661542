import serverCall from "../serverCall";

const getAllCourses = async (pageNumber, pageSize, searchText) => {
  if (searchText) {
    const response = await serverCall.get(
      `/course/getAll/web?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`
    );
    return response;
  } else {
    const response = await serverCall.get(
      `/course/getAll/web?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return response;
  }
};

const getCourseDetails = async (id) => {
  const response = await serverCall.get(`/course/get/${id}`);
  return response;
};

const createCourse = async (body) => {
  const response = await serverCall.post(`/course/system/create`, body);
  return response;
};
const updateCourse = async (id, body) => {
  const response = await serverCall.post(`/course/update/${id}`, body);
  return response;
};

const deleteCourse = async (id) => {
  const response = await serverCall.delete(`/course/delete/${id}`);
  return response;
};

const CourseServices = {
  getAllCourses,
  createCourse,
  getCourseDetails,
  deleteCourse,
  updateCourse,
};

export default CourseServices;
