import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthServices from "../../services/auth.service";
import { enqueueSnackbar } from "notistack";
import { ANCHORORIGIN, ERROR } from "../../utils/constants";

const SetNewPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const token = state?.token;
  const email = state?.email;

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      ConfirmNewPassword: "",
    },
    validationSchema: yup.object().shape({
      newPassword: yup.string().required("Please Enter Password!"),
      ConfirmNewPassword: yup.string().required("Please Enter Password!"),
    }),
    onSubmit: async (values) => {
      const { newPassword, ConfirmNewPassword } = values;
      if (newPassword !== ConfirmNewPassword) {
        setIsError(true);
        return;
      }
      try {
        if (state?.path === "forgotPassword") {
          const response = await AuthServices.changeForgotPassword(
            email,
            newPassword,
            token
          );

          navigate("/login");
        } else {
          const response = await AuthServices.setNewPassword(
            newPassword,
            token
          );
          navigate("/login");
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    },
  });

  return (
    <>
      <div className="login-forms">
        <div className="container">
          <div className="login-main position-sticky">
            <div className="title">Set New Password</div>
            <form onSubmit={formik.handleSubmit}>
              <div className="user__details my-4">
                <div className="input__box w-100">
                  <span className="details">New Password</span>
                  <div className="input-field">
                    <input
                      type="password"
                      name="newPassword"
                      onChange={formik.handleChange}
                      value={formik.values.newPassword}
                      placeholder="Password"
                    />
                  </div>
                </div>{" "}
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className="error-text">{formik.errors.newPassword}</div>
                )}
                <div className="input__box w-100">
                  <span className="details">Confirm Password</span>
                  <div className="input-field">
                    <input
                      type="password"
                      name="ConfirmNewPassword"
                      onChange={formik.handleChange}
                      value={formik.values.ConfirmNewPassword}
                      placeholder="Confirm Password"
                    />
                  </div>
                  {formik.touched.ConfirmNewPassword &&
                    formik.errors.ConfirmNewPassword && (
                      <div className="error-text">
                        {formik.errors.ConfirmNewPassword}
                      </div>
                    )}
                  {isError && (
                    <div className="error-text">Password Must be same</div>
                  )}
                </div>
              </div>
              <div>
                <button
                  className="login p-3 h-100 w-100 fw-semibold fs-6"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetNewPassword;
