import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoSearchSharp } from "react-icons/io5";
import GameHistoryServices from "../../../../../services/gameHistory.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const GameHistoryScoreboard = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getGame = async () => {
    try {
      const response = await GameHistoryServices.getGame(id);
      setData(response.data.apiresponse.data);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getGame();
  }, [id]);

  return (
    <>
      <div>
        <div className="container mb-8">
          <div>
            <div className="row m-0 align-items-center justify-content-between py-4">
              <div className="col-lg-6 col-12 px-0 pb-3 pb-lg-0 d-flex align-items-center gap-3">
                <IoIosArrowBack
                  onClick={handleBack}
                  className="fs-3"
                  style={{ color: "#00390D", cursor: "pointer" }}
                />
                <h1 className="fs-3 m-0 fw-semibold lh-lg">
                  {data.golfClubName}
                </h1>
              </div>
              <div className="col-lg-6 row m-0 p-0">
                <div className="col-md-6 p-0 d-flex align-items-center justify-content-end justify-content-md-start gap-1">
                  <p className="m-0">Game Date:</p>
                  <span>{moment(data.updatedat).format("DD/MM/YYYY")}</span>
                </div>
                <div className="col-md-6 p-0 d-flex align-items-center gap-1 border border-black rounded-2">
                  <div className="p-2">
                    <IoSearchSharp />
                  </div>
                  <input
                    type="text"
                    className="py-2 text-black"
                    placeholder="Enter Game Deck Name..."
                  />
                </div>
              </div>
            </div>

            {/* Score Data */}
            {data !== "" && data != "" ? (
              <div className="score-data rounded-4 overflow-hidden bg-white">
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center border-top-0 border-start-0">
                          Hole
                        </th>
                        {data?.courseHoles?.map((hole, index) => {
                          return (
                            <th className="border-top-0" key={index}>
                              {hole.index}
                            </th>
                          );
                        })}
                        <th rowSpan={2} className="border-end-0 text-center">
                          Total
                        </th>
                      </tr>
                      <tr>
                        <th className="text-center border-top-0 border-start-0">
                          Par
                        </th>
                        {data?.courseHoles?.map((hole, index) => {
                          return (
                            <th className="border-top-0" key={index}>
                              {hole.par}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.players?.map((player, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {player.name ? player.name : "--"}
                            </td>
                            {player?.scores?.map((score) => {
                              return <td>{score.score}</td>;
                            })}
                            <td className="border-end-0">
                              {player?.scores?.reduce((accumulator, score) => {
                                return accumulator + score.score;
                              }, 0)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="my-course-data rounded-4 overflow-hidden bg-white px-3 py-3">
                No Data Available
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GameHistoryScoreboard;
