import React, { useEffect, useRef, useState } from "react";
import "./Login.css";
import { useLocation, useNavigate } from "react-router-dom";
import AuthServices from "../../services/auth.service";
import { setLoggedIn } from "../../store/slice/auth";
import { useDispatch } from "react-redux";
import { ANCHORORIGIN, ERROR } from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import Countdown from "react-countdown";

const VerifyOTP = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(Date.now() + 120000);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="tx-danger cursor-pointer" onClick={resendOTP}>
          Resend OTP!
        </div>
      );
    } else {
      return (
        <div>
          OTP Expired In:{" "}
          <span className="tx-danger fw-bold">
            0{minutes}:{String(seconds).padStart(2, "0")}
          </span>
        </div>
      );
    }
  };

  const handleChange = (index, e) => {
    const { maxLength, value } = e.target;
    if (value.length === maxLength) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
    const otpArray = inputRefs.map((ref) => ref.current.value);
    setOtp(otpArray.join(""));
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && e.target.value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleKeyPress = (index, e) => {
    if (e.target.value.length === 1) {
      e.preventDefault();
    }
  };

  const verifyOTP = async () => {
    try {
      if (state?.path === "forgotPassword") {
        const response = await AuthServices.verifyForgotOTP(
          state?.email,
          otp,
          state?.token,
          state?.path
        );

        navigate("/setNewPassword", {
          state: {
            token: state?.token,
            path: state?.path,
            email: state?.email,
          },
        });
      } else {
        const response = await AuthServices.verifyOTP(
          state?.email,
          otp,
          state?.token,
          state?.path
        );
        const token = response.data.apiresponse.data.token;
        // dispatch(setLoggedIn(true));
        navigate("/setNewPassword", {
          state: {
            token: state?.token,
            path: state?.path,
            email: state?.email,
          },
        });
      }
    } catch (e) {
      console.log("error", e);
      enqueueSnackbar(e.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const resendOTP = async () => {
    try {
      await AuthServices.resendOTP(state?.email);
      setTimer(Date.now() + 120000);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  return (
    <>
      <div className="login-forms">
        <div className="container">
          <div className="login-main position-sticky">
            <div className="title">OTP Verify</div>
            <form action="#">
              <p className="text-white text-center mt-4 fs-5">
                Enter 4 digits verification code sent to your Email
                <br />
                {state?.email}
              </p>
              <div className="user__details my-4 d-flex justify-content-center flex-column align-items-center">
                <div className="input__box otp-input d-flex gap-3 justify-content-center">
                  <div className="input-field">
                    <input
                      type="number"
                      maxLength={1}
                      placeholder=""
                      required
                      ref={inputRefs[0]}
                      onChange={(e) => handleChange(0, e)}
                      onKeyDown={(e) => handleBackspace(0, e)}
                      onKeyPress={(e) => handleKeyPress(0, e)}
                    />
                  </div>
                  <div className="input-field">
                    <input
                      type="number"
                      placeholder=""
                      maxLength={1}
                      required
                      ref={inputRefs[1]}
                      onChange={(e) => handleChange(1, e)}
                      onKeyDown={(e) => handleBackspace(1, e)}
                      onKeyPress={(e) => handleKeyPress(1, e)}
                    />
                  </div>
                  <div className="input-field">
                    <input
                      type="number"
                      placeholder=""
                      maxLength={1}
                      required
                      ref={inputRefs[2]}
                      onChange={(e) => handleChange(2, e)}
                      onKeyDown={(e) => handleBackspace(2, e)}
                      onKeyPress={(e) => handleKeyPress(2, e)}
                    />
                  </div>
                  <div className="input-field">
                    <input
                      type="number"
                      placeholder=""
                      maxLength={1}
                      required
                      ref={inputRefs[3]}
                      onChange={(e) => handleChange(3, e)}
                      onKeyDown={(e) => handleBackspace(3, e)}
                      onKeyPress={(e) => handleKeyPress(3, e)}
                    />
                  </div>
                </div>
                <div className="resend-otp">
                  <p className="text-white d-flex gap-3 align-items-center">
                    <Countdown date={timer} renderer={renderer} />
                  </p>
                </div>
              </div>
              <div>
                <button
                  className="login p-3 h-100 w-100 fw-semibold fs-6"
                  type="button"
                  onClick={() => verifyOTP()}
                >
                  Verify
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
