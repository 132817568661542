import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SubsriptionServices from "../../../../../services/subscription.service";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const ManageSubscription = (props) => {
  const { show, onHide, handlePromoCOdeClose } = props;
  const [plans, setPlans] = useState([]);
  const [existPlan, setExistPlan] = useState([]);
  const mulliganStorageString = localStorage.getItem("mulligan-storage");
  const isLoggedIn = JSON.parse(mulliganStorageString);

  const checkSubscription = async () => {
    try {
      const response = await SubsriptionServices.checkSubscription();
      setExistPlan(response.data.apiresponse.data);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const getAllPlans = async () => {
    try {
      const response = await SubsriptionServices.getAllPlans();
      setPlans(response.data.apiresponse.data);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };
  const cancelSubscription = async () => {
    try {
      await SubsriptionServices.cancelSubscription();
      onHide();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
    handlePromoCOdeClose();
  };

  const createSubscription = async (planType, planPrice) => {
    try {
      const response = await SubsriptionServices.createSubscription(
        planType,
        planPrice
      );
      window.open(response.data.apiresponse.data);
      onHide();
      handlePromoCOdeClose();
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn?.isLoggedIn) {
      getAllPlans();
      checkSubscription();
    }
  }, []);

  return (
    <>
      <div className="add-courses">
        <Modal
          centered
          show={show}
          backdrop="static"
          keyboard={false}
          className="rounded-5"
        >
          <Modal.Header
            closeButton
            onClick={onHide}
            className="card-title py-2"
          >
            <Modal.Title>Manage Subscription</Modal.Title>
          </Modal.Header>
          <form>
            <div className="p-3 position-sticky bg-white subscription-modals">
              <div className="dailog-card py-4">
                <div className="position-sticky d-flex flex-column gap-2">
                  {plans.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.planType === "MONTHLY" && (
                          <div
                            className="input-field d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <span className="text-white">
                              Monthly Subscription
                            </span>
                            <div className="border-0 gap-3 justify-content-center">
                              {existPlan.isValidUser &&
                              existPlan.planType === "MONTHLY" ? (
                                <button
                                  className="btns-d btns subscription"
                                  type="button"
                                  onClick={cancelSubscription}
                                >
                                  Unsubscribe
                                </button>
                              ) : (
                                <button
                                  className="btns-d btns subscription"
                                  type="button"
                                  onClick={() =>
                                    createSubscription(
                                      item.planType,
                                      item.planPrice
                                    )
                                  }
                                >
                                  {`$${item.planPrice}/Month`}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {plans.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.planType === "YEARLY" && (
                          <div
                            className="input-field d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <span className="text-white">
                              Yearly Subscription
                            </span>
                            <div className="border-0 gap-3 justify-content-center">
                              {existPlan.isValidUser &&
                              existPlan.planType === "YEARLY" ? (
                                <button
                                  className="btns-d btns subscription"
                                  type="button"
                                  onClick={cancelSubscription}
                                >
                                  Unsubscribe
                                </button>
                              ) : (
                                <button
                                  className="btns-d btns subscription"
                                  type="button"
                                  onClick={() =>
                                    createSubscription(
                                      item.planType,
                                      item.planPrice
                                    )
                                  }
                                >
                                  {`$${item.planPrice}/Year`}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Modal.Footer
              className="border-0"
              style={{ background: "#025314" }}
            >
              <div className="d-flex justify-content-between w-100 align-items-center m-0">
                <p className="text-start m-0 text-white">
                  Your Subscription will renew on
                </p>
                <span className="text-white">12/04/2023</span>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ManageSubscription;
