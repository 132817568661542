import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AuthServices from "../../services/auth.service";
import { enqueueSnackbar } from "notistack";
import { ANCHORORIGIN, ERROR } from "../../utils/constants";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required("Please Enter Email!"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await AuthServices.forgotPasword(values.email);
        const token = response.data.apiresponse.data.token;
        navigate("/verify-otp", {
          state: {
            token: token,
            path: "forgotPassword",
            email: values.email,
          },
        });
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    },
  });

  return (
    <>
      <div className="login-forms">
        <div className="container">
          <div className="login-main position-sticky">
            <div className="title">Forgot Password</div>
            <form onSubmit={formik.handleSubmit}>
              <div className="user__details my-4">
                <div className="input__box w-100">
                  <span className="details">Email</span>
                  <div className="input-field">
                    <input
                      type="text"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      placeholder=""
                    />
                  </div>{" "}
                  {formik.touched.email && formik.errors.email && (
                    <div className="error-text">{formik.errors.email}</div>
                  )}
                </div>
              </div>
              <div>
                <button
                  className="login p-3 h-100 w-100 fw-semibold fs-6"
                  type="submit"
                >
                  Send OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
