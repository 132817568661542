import React, { useEffect, useState } from "react";
import ManageSubscription from "./ManageSubscription";
import useDialog from "../../../../../hooks/useDialog";
import UserServices from "../../../../../services/user.service";
import { useFormik } from "formik";
import SubsriptionServices from "../../../../../services/subscription.service";
import AuthServices from "../../../../../services/auth.service";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../../../../store/slice/auth";
import { useNavigate } from "react-router-dom";
import { ANCHORORIGIN, ERROR } from "../../../../../utils/constants";
import { enqueueSnackbar } from "notistack";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useDialog();
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [oldEmail, setOldEmail] = useState("");

  const getUserDetails = async () => {
    try {
      const response = await UserServices.getUserDetails();
      setData(response.data.apiresponse.data);
      const result = response.data.apiresponse.data;
      formik.setFieldValue("firstName", result.firstName);
      formik.setFieldValue("lastName", result.lastName);
      formik.setFieldValue("countryCode", result.countryCode);
      formik.setFieldValue("phone", result.phone);
      formik.setFieldValue("email", result.email);
      setOldEmail(result.email);
    } catch (error) {
      enqueueSnackbar(error.response.data.apierror.message, {
        variant: ERROR,
        anchorOrigin: ANCHORORIGIN,
      });
    }
  };

  const logout = () => {
    dispatch(setLoggedIn(false));
    AuthServices.logout();
    navigate("/home");
    // window.location = "/home";
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "",
      email: "",
      birthDate: 0,
    },
    onSubmit: async (values) => {
      try {
        await UserServices.updateUserDetails(values);

        if (values.email !== oldEmail) {
          await UserServices.updateEmail(values.email);
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.apierror.message, {
          variant: ERROR,
          anchorOrigin: ANCHORORIGIN,
        });
      }
    },
  });

  return (
    <>
      <div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-center justify-content-between py-4">
              <h1 className="fs-3 m-0 fw-semibold lh-lg">Profile</h1>
              <div className="d-flex justify-content-end">
                <button
                  className="add-course btns"
                  type={isEdit ? "button" : "submit"}
                  onClick={() => setIsEdit(!isEdit)}
                >
                  {isEdit ? "Submit" : "Edit Profile"}
                </button>
              </div>
            </div>
            <div className="row m-0 mb-4">
              <div className="col-12 col-md-6 p-0 ps-md-0">
                <div className="mb-4 profile-input">
                  <p>First Name</p>
                  <input
                    className="w-100 border-0"
                    type="text"
                    name="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    disabled={!isEdit}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 pe-md-0 ps-md-4 p-0">
                <div className="mb-4 profile-input">
                  <p>Last name</p>
                  <input
                    className="w-100 border-0"
                    type="text"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="row m-0 mb-4">
              <div className="col-12 col-md-6 ps-md-0 p-0">
                <div className="mb-4 profile-input">
                  <p>Phone Number </p>
                  <input
                    className="w-100 border-0"
                    type="number"
                    name="phone"
                    // value={data.phone !== null ? data.phone : "--"}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    disabled={!isEdit}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 pe-md-0 ps-md-4 p-0">
                <div className="mb-4 profile-input">
                  <p>Your country</p>
                  <input
                    className="w-100 border-0"
                    type="text"
                    name="countryCode"
                    // value={data.countryCode}
                    onChange={formik.handleChange}
                    value={formik.values.countryCode}
                    disabled={!isEdit}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="mb-4 profile-input">
                <p>Email</p>
                <input
                  className="w-100 border-0"
                  type="email"
                  name="email"
                  // value={data.email}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled={!isEdit}
                />
              </div>
            </div>
            <div className="pt-4">
              <div className="position-sticky">
                {!isEdit && (
                  <div className="sub-img" onClick={handleOpen}>
                    <button className="btns subscrip mb-4" type="button">
                      Manage Subscription
                    </button>
                  </div>
                )}
                <button className="btns logout" onClick={logout}>
                  Log Out
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ManageSubscription show={open} onHide={handleClose} />
    </>
  );
};

export default MyAccount;
