import React from "react";
import { Modal } from "react-bootstrap";
import DeleteAlert from "../../../../../assets/images/icon/alert.svg";

const DeleteAll = (props) => {
  const { finalDeleteButton, show, onHide, deleteID, tab } = props;
  return (
    <>
      <div className="add-courses">
        <Modal
          centered
          {...props}
          show={show}
          backdrop="static"
          keyboard={false}
          className="rounded-5"
        >
          <form style={{ background: "#F3F3F3" }}>
            <div className="p-3 d-flex align-items-center justify-content-center flex-column rounded-3 overflow-hidden">
              <div>
                <img src={DeleteAlert} alt="Delete" />
              </div>
              <h3 className="text-black">Are you sure!</h3>
              <h3 className="fs-semibold" style={{ color: "#ff0000" }}>
                You want to delete {tab}?
              </h3>
              <div className="w-75 d-flex align-items-center gap-3 mt-3">
                <button
                  className="w-100 border-0 bg-white rounded-2 p-3"
                  type="button"
                  onClick={onHide}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => finalDeleteButton(deleteID)}
                  className="w-100 border-0 text-white rounded-2 p-3"
                  style={{ background: "#1D8A5D" }}
                >
                  Yes
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default DeleteAll;
